<template>
    <div>
        <iframe class="live-iframe" v-if="iframe_url != ''" :src="iframe_url"></iframe>
    </div>
</template>
<script>
    export default {
        name:'Live',
        data() {
            return {
                params:this.$route.params,
                iframe_url:''
            }
        },
        created() {
            let _this = this;
            _this.getLiveUrl();
            // console.log(_this.params.code);
        },
        methods: {
            getLiveUrl() {
                let _this = this;
                this.$post('live/user/address', {code:_this.params.code}).then(res =>{
                    if (res.data.data.back_url == '/') {
                        this.$toast.setDefaultOptions({ duration: 1000 });
                        this.$toast(res.data.msg);
                        setTimeout(res => {
                            window.location.href = '/';
                        }, 1000);
                    } else {
                        _this.iframe_url = res.data.data.iframe_url;
                    }
                })
            }
        }
    }
</script>

